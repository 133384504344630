/*
 * Types for bijtelling
 */

export type BijtellingYear = 2020 | 2021 | 2022 | 2023 | 2024

interface BijtellingDetailsForYear {
    year: BijtellingYear
    taxBrackets: number[]
    discountedRateCutOff: number
    discountedRate: number
    regularRate: number
}

interface GetNetBijtellingPerMonthParam {
    bijtellingYear: BijtellingYear
    fiscalValue: number
    ownContributionPerMonth: number
    taxBracketNr: 1 | 2
}

export interface BijtellingResults {
    bracketOneResultCurrentYear: number
    bracketTwoResultCurrentYear: number
    bracketOneResultNextYear: number
    bracketTwoResultNextYear: number
}

export const currentYear: BijtellingYear = 2023
export const nextYear: BijtellingYear = 2024

/*
 * Variables which impact the yearly differences in bijtelling for electric cars
 */

const bijtellingYears: BijtellingDetailsForYear[] = [
    {
        year: 2020,
        taxBrackets: [37.35, 49.5],
        discountedRateCutOff: 45000,
        discountedRate: 8,
        regularRate: 22,
    },
    {
        year: 2021,
        taxBrackets: [37.1, 49.5],
        discountedRateCutOff: 40000,
        discountedRate: 12,
        regularRate: 22,
    },
    {
        year: 2022,
        taxBrackets: [37.07, 49.5],
        discountedRateCutOff: 35000,
        discountedRate: 16,
        regularRate: 22,
    },
    {
        year: 2023,
        taxBrackets: [36.09, 49.5],
        discountedRateCutOff: 30000,
        discountedRate: 16,
        regularRate: 22,
    },
    {
        year: 2024,
        taxBrackets: [36.09, 49.5],
        discountedRateCutOff: 30000,
        discountedRate: 16,
        regularRate: 22,
    },
]

/*
 * Electric car bijtelling functions
 */

function getGrossBijtellingPerYear(
    netFiscalValue: number,
    { discountedRate, regularRate, discountedRateCutOff }: BijtellingDetailsForYear,
    ownContributionPerMonth: number
): number {
    const discountedRateFiscalValue = netFiscalValue <= discountedRateCutOff ? netFiscalValue : discountedRateCutOff
    const regularRateFiscalValue = netFiscalValue <= discountedRateCutOff ? 0 : netFiscalValue - discountedRateCutOff

    const discountedRatePrice = (discountedRateFiscalValue * discountedRate) / 100
    const regularRatePrice = (regularRateFiscalValue * regularRate) / 100

    const grossBijtellingPerYearBeforeOwnContribution = discountedRatePrice + regularRatePrice

    return grossBijtellingPerYearBeforeOwnContribution - ownContributionPerMonth * 12
}

export function getNetBijtellingPerMonth({
    bijtellingYear,
    fiscalValue,
    ownContributionPerMonth,
    taxBracketNr,
}: GetNetBijtellingPerMonthParam): number {
    const bijtellingDetails = bijtellingYears.find(({ year }) => year === bijtellingYear) || bijtellingYears[0]

    const grossBijtellingPerYear = getGrossBijtellingPerYear(
        fiscalValue,
        bijtellingDetails,
        ownContributionPerMonth > 0 ? ownContributionPerMonth : 0
    )

    const result = (grossBijtellingPerYear * bijtellingDetails.taxBrackets[taxBracketNr - 1]) / 100 / 12

    return result > 0 ? result : 0
}

export const getBijtellingWithoutOwnContribution = (fiscalValue: number): BijtellingResults => {
    const baseParam = { fiscalValue, ownContributionPerMonth: 0 }

    return {
        bracketOneResultCurrentYear: getNetBijtellingPerMonth({
            ...baseParam,
            bijtellingYear: currentYear,
            taxBracketNr: 1,
        }),
        bracketTwoResultCurrentYear: getNetBijtellingPerMonth({
            ...baseParam,
            bijtellingYear: currentYear,
            taxBracketNr: 2,
        }),
        bracketOneResultNextYear: getNetBijtellingPerMonth({ ...baseParam, bijtellingYear: nextYear, taxBracketNr: 1 }),
        bracketTwoResultNextYear: getNetBijtellingPerMonth({ ...baseParam, bijtellingYear: nextYear, taxBracketNr: 2 }),
    }
}

/*
 * Non electric car bijtelling functions
 */

const getNetBijtellingPerMonthNonElectricCar = (
    fiscalValue: number,
    bijtellingYear: BijtellingYear,
    taxBracketNr: 1 | 2
): number => {
    const bijtellingDetails = bijtellingYears.find(({ year }) => year === bijtellingYear) || bijtellingYears[0]

    const grossBijtellingPerYear = (fiscalValue * bijtellingDetails.regularRate) / 100

    return (grossBijtellingPerYear * bijtellingDetails.taxBrackets[taxBracketNr - 1]) / 100 / 12
}

export const getNetBijtellingResultsNonElectricCar = (fiscalValue: number): BijtellingResults => {
    return {
        bracketOneResultCurrentYear: getNetBijtellingPerMonthNonElectricCar(fiscalValue, currentYear, 1),
        bracketTwoResultCurrentYear: getNetBijtellingPerMonthNonElectricCar(fiscalValue, currentYear, 2),
        bracketOneResultNextYear: getNetBijtellingPerMonthNonElectricCar(fiscalValue, nextYear, 1),
        bracketTwoResultNextYear: getNetBijtellingPerMonthNonElectricCar(fiscalValue, nextYear, 2),
    }
}
